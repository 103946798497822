import { useSelector } from 'react-redux';
import { Trigger } from '../../../../../../@Types/Activity';
import { ActivityTriggerTypes } from '../../../../../../constants/ActivityTypes';
import RoundedButton from '../../../../../../shared/RoundedButton/RoundedButton';
import styles from './SubmitBtns.module.css';
import { RootState } from '../../../../../../utils/_store';
import { Branding } from '../../../../../../@Types/Branding';

interface SubmitBtnsProps {
    triggers?: Trigger[];
    defaultTrigger?: Trigger;
    postview?: boolean;
    viewFormLabel?: string | null;
    onSubmit?: Function;
    handleSubmit?: (
        trigger?: Trigger,
        values?: Record<string, any>
    ) => Promise<void>;
    fontSize?: string;
    idTrigger?: string | null;
    padding?: string;
}

function SubmitBtns({
    onSubmit,
    triggers,
    idTrigger,
    handleSubmit,
    defaultTrigger,
    postview = false,
    viewFormLabel,
    fontSize = '18px',
    padding = '8px 16px',
}: SubmitBtnsProps): JSX.Element {
    const branding = useSelector((state: RootState) => state.site.branding!);
    return (
        <div className={'flexbox ' + styles.formBtnContainer}>
            {triggers?.map((trigger) => (
                <div
                    style={
                        idTrigger === trigger.id && postview
                            ? {
                                  border:
                                      '2px solid ' +
                                      branding.colors.primaryColor,
                                  borderRadius: 12,
                              }
                            : {}
                    }
                    key={trigger.id}
                >
                    <RoundedButton
                        onClick={async (): Promise<void> => {
                            const values = await onSubmit?.();
                            if (values) await handleSubmit?.(trigger, values);
                        }}
                        loading={idTrigger === trigger.id && !postview}
                        disabled={postview || !!idTrigger}
                        backgroundColor={calcTriggerTypeColor(
                            trigger.triggerType,
                            branding.colors
                        )}
                        text={trigger.label}
                        fontSize={fontSize}
                        padding={padding}
                    />
                </div>
            ))}
            {defaultTrigger && (
                <div
                    style={
                        idTrigger === defaultTrigger.id && postview
                            ? {
                                  border:
                                      '2px solid ' +
                                      branding.colors.primaryColor,
                                  borderRadius: 12,
                              }
                            : {}
                    }
                    key={'main'}
                >
                    <RoundedButton
                        onClick={async (): Promise<void> => {
                            const values = await onSubmit?.();
                            if (values)
                                await handleSubmit?.(defaultTrigger, values);
                            else if (!onSubmit)
                                await handleSubmit?.(defaultTrigger, {});
                        }}
                        loading={idTrigger === defaultTrigger.id && !postview}
                        disabled={postview || !!idTrigger}
                        backgroundColor={calcTriggerTypeColor(
                            defaultTrigger.triggerType,
                            branding.colors
                        )}
                        text={defaultTrigger.label}
                        fontSize={fontSize}
                        padding={padding}
                    />
                </div>
            )}
            {viewFormLabel && (
                <div key={'viewMore'}>
                    <RoundedButton
                        onClick={(): void => {
                            handleSubmit?.();
                        }}
                        backgroundColor={calcTriggerTypeColor(
                            ActivityTriggerTypes.DEFAULT,
                            branding.colors
                        )}
                        text={viewFormLabel}
                        fontSize={fontSize}
                        padding={padding}
                    />
                </div>
            )}
        </div>
    );
}
export default SubmitBtns;

function calcTriggerTypeColor(
    type: ActivityTriggerTypes,
    styles: Branding['colors']
): string {
    switch (type) {
        case ActivityTriggerTypes.DEFAULT:
            return styles.primaryColor;
        case ActivityTriggerTypes.ERROR:
            return 'var(--error)';
        case ActivityTriggerTypes.CONTRAST:
            return styles.contrastColor;
        default:
            return styles.primaryColor;
    }
}
